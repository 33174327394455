<template>
  <pz-form-flujo
    v-model="formulario1['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form1_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario1"
      status-icon
      :rules="rules_formulario1"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model="formulario1.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
              :disabled="isReadOnly('nombres')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model="formulario1.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
              :disabled="isReadOnly('apellidos')"
              style="text-transform:uppercase;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <pz-identity-document
            v-model="formulario1.identity"
            prop="identity"
            type="person"
            :number-hidden="condocumento"
            :type-label="$t('forms_flujo.form1_persona.UY.tipo_doc')"
            :number-label="$t('forms_flujo.form1_persona.UY.identity_doc')"
          />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              ref="fecha_nacimiento"
              v-model="formulario1.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_nacimiento')"
              :disabled="isReadOnly('fecha_nacimiento')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form1_persona.UY.telefono')"
            :error="errors.get('telefono')"
          >
            <el-input
              ref="telefono"
              v-model="formulario1.telefono"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.UY.telefono')"
              :disabled="isReadOnly('telefono')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              ref="fecha_ingreso"
              v-model="formulario1.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              :disabled="isjubilado"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form1_persona.UY.fecha_ingreso')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="relacion_laboral_id"
            :label="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
          >
            <el-select
              ref="relacion_laboral_id"
              v-model="formulario1.relacion_laboral_id"
              clearable
              filterable
              :placeholder="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
            >
              <el-option
                v-for="item in relacion_laboral"
                :key="item.id"
                :label="item.relacion"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.BNF.tipo_trabajo')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cancelar_prestamos"
            :label="$t('forms_flujo.form1_persona.BNF.cancelar_prestamos')"
            :error="errors.get('cancelar_prestamos')"
          >
            <el-select
              ref="cancelar_prestamos"
              v-model="formulario1.cancelar_prestamos"
              clearable
              :placeholder="$t('forms_flujo.form1_persona.BNF.cancelar_prestamos')"
              @change="modelDialog"
            >
              <el-option
                v-for="item in tipo_prestamo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form1_persona.BNF.cancelar_prestamos')"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog
        title="Información"
        width="30%"
        :visible.sync="dialogVisible"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <span>Esta solicitud cancelará todos sus préstamos personales de consumo vigentes</span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            type="primary"
            @click="dialogVisible = false"
          >Aceptar</el-button>
        </span>
      </el-dialog>
    </el-form>
  </pz-form-flujo>
</template>
<script>

import {required, EspacioBlanco} from "@/commons/utils/Rules";
import 'element-ui/lib/theme-chalk/display.css'
import PzBaseForm1 from "@/components/Flujo/Formularios/Formularios1/baseForm1";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {
    getRelacionesLaborales
} from "@/routes/api/resources";
import {getFromLocal} from "@/commons/utils/storage";

export default {
    name: 'PzPersonForm1Bnf',
    components: {  PzFormFlujo, PzIdentityDocument},
    mixins: [PzBaseForm1],
    data() {
        var check_Fecha = (rule, value, callback) => {

            localStorage.setItem('isjubilado',true)
            if (this.formulario1.relacion_laboral_id!=4)
                localStorage.setItem('isjubilado',false)

            if (this.formulario1.relacion_laboral_id!=4 && !value) {
                return callback(new Error(this.$t('rules.incomplete')));
            }

            return callback();
        };
        return {
            dialogVisible: false,
            reference: 'formulario1',
            marital_status: [],
            sucursales: [],
            relacion_laboral: [ ],
            tipo_prestamo:[
                {id:'si',name:'Si'},
                {id:'no',name:'No'}
                ],
            formulario1: {
                nombres: '',
                apellidos: '',
                telefono: '',
                fecha_nacimiento: '',
                identity: {document: 1},
                type_prestamo:'',
                consulta_clearing:0,
                consulta_bcu:0,
                fecha_ingreso: '',
                relacion_laboral_id:'',
                cancelar_prestamos:''

            },
            view_sucursal : false,
            rules_formulario1: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco()],
                nombres: [required(''),EspacioBlanco()],
                apellidos: [required(''),EspacioBlanco()],
                telefono: [required('')],
                fecha_nacimiento: [required('')],
                fecha_ingreso: [{validator: check_Fecha, trigger: 'blur'}],
                cancelar_prestamos: [required('')],
                relacion_laboral_id: [required('')],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return Date.now() < time.getTime();
                },
            },
        };
    },
    computed:{
      isjubilado(){
          return (this.formulario1.relacion_laboral_id && this.formulario1.relacion_laboral_id=='4')
      },
        condocumento(){
            var docmun = localStorage.getItem('docnum');
            if(docmun){
                return    true;
            }

            return false
        },

    },
    created: function () {
        this.$store.dispatch('loader/up', {trigger: this.$options.name})
        var docmun = localStorage.getItem('docnum');
        if(docmun)
          this.formulario1.identity.number = docmun;
        var re = getRelacionesLaborales().then((res) => {
            this.relacion_laboral = res.data;
        }).catch(() => {
            this.relacion_laboral = [];
        });
        Promise.all([ re]).then(() => {

        }).finally(() => {
            this.$store.dispatch('loader/down',{trigger:this.$options.name})
        });
    },
    methods: {
        modelDialog(value) {
            if(value==='si')
                this.dialogVisible = true;
        },
        closeDialog() {
            this.dialogVisible = false;
        },
      isReadOnly(data){
        let data_client = getFromLocal('data_client');
        if(data_client)
          return data_client[data]?true:false
        else
          return false;
      },

    },


}
</script>

